document.addEventListener("DOMContentLoaded", function () {
	const form = document.getElementById("eventForm");
	if (form) {
		const eName = document.getElementById("eName");
		const eFrom = document.getElementById("eFrom");
		const eTo = document.getElementById("eTo");
		const addEventButton = document.getElementById("addEvent");

		function validateForm() {
			const name = eName.value.trim();
			const from = moment(eFrom.value, "D. M. YYYY H:mm");
			const to = moment(eTo.value, "D. M. YYYY H:mm");
			if (name && eFrom.value && eTo.value && from < to) {
				addEventButton.disabled = false;
			} else {
				addEventButton.disabled = true;
			}
		}

		eName.addEventListener("input", validateForm);
		eFrom.addEventListener("input", validateForm);
		eTo.addEventListener("input", validateForm);
	}
});

document.addEventListener("DOMContentLoaded", function () {
	const form = document.getElementById("eventForm");
	if (form) {
	const addEventButton = document.getElementById("addEvent");
    const listGrid = document.getElementById('listGrid'); 

	form.addEventListener("submit", function (event) {
		event.preventDefault();
		if (addEventButton.disabled) return;

		const formData = new FormData(form);

		fetch("../inc/saveEvent.php", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					const eventId = data.id;
                    const eName = formData.get('eName');
                    const eFrom = formData.get('eFrom');
                    const eTo = formData.get('eTo');

                    const eventLink = document.createElement('a');
                    eventLink.href = `/akce-${eventId}/`;
                    eventLink.innerHTML = `<h2>${eName}</h2><p>${eFrom}</p><p>${eTo}</p>`;
                    const eventItem = document.createElement('div');
                    eventItem.appendChild(eventLink);

                    listGrid.insertBefore(eventItem, listGrid.firstChild);

					alert("Údaje byly úspěšně uloženy");
					form.reset();
					addEventButton.disabled = true;
				} else {
					alert("Chyba při ukládání údajů: " + data.message);
				}
			})
			.catch((error) => {
				alert("Chyba při komunikaci se serverem: " + error);
			});
	});
	}
});
